// Libraries
import Vue from 'vue'
import { createPinia, PiniaVuePlugin  } from 'pinia'

import App from './App.vue'
import PrimeVue from 'primevue/config';

import VueToast from 'vue-toast-notification';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core import 'css'
import 'primeicons/primeicons.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import 'primeflex/primeflex.css';
// import 'primeflex/primeflex.css';

const pinia = createPinia()

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js", { scope: "/" })
    .then((registration) => {
      // registration worked
      // console.log("Registration succeeded.", registration);
      // registration.unregister().then((unregistered) => {
      //   if (unregistered) console.log("unregister successful")
      // });
    })
    .catch((error) => {
      // registration failed
      console.error(`Registration failed with ${error}`);
    });
}

Vue.use(VueToast);
Vue.use(PiniaVuePlugin)

Vue.use(PrimeVue, {
  ripple: true,
  locale: {
    firstDayOfWeek: 1,
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ],
    today: "Hoy",
    clear: "Borrar",
    weekHeader: "Sm"
  }
});



//Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

//Vuex

//Vue Router

// import router from './Router'
import router from '@/router/index'

// App


import import_components from './import_components'
// import_components(Vue);
import_components.load(Vue)
// console.log(import_components)

// Vue.use(VueResource);
Vue.config.productionTip = false

import * as offline from './data/localdb';
Vue.prototype.$offline = offline

import SiisUtils from './siis_utils';
Vue.prototype.$rest = SiisUtils(store, Vue, axios)
Vue.prototype.$utils = {
  cloneObj: obj => JSON.parse(JSON.stringify(obj)),
}



import store from './store'

import './data/localdb'
import './data/local_idb'



new Vue({
  render: h => h(App),
  store,
  router,
  pinia
}).$mount('#app')

