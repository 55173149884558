export async function combineActuations(appOfflineActuations, onlineActuations) {
  const concatedActuations = appOfflineActuations.concat(onlineActuations);
  let combinedActuations = [];
  concatedActuations.map(async actuation => {
    combinedActuations.push({
      type: actuation?.type === 'offlineAppActuation' ? 'actuacionesRepository' : 'actuationsRepository',
      id: actuation.id,
      siis_state: await actuation.siis_state
    });
  });
  return await combinedActuations;
}